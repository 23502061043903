import React from 'react'
import Ruler from '../components/Ruler'
import { useAppSelector } from '../app/hooks';

export default function Height() {
  const path = useAppSelector((state) => state.config.path);
  return (
   <section id="sec-height" className={'sec-height' +  (path == "height"? ' animate__animated animate__backInDown animate__delay-1s': ' animate__animated animate__backOutUp animate__delay-1s hidden-section')}>
    <h1>Height</h1>
    <Ruler />
    <a className='btn btn-weight' href='#sec-height'>
                        Next
                        </a>
   </section>
  )
}
