import React, { useState } from 'react';
import WeightJson from '../assets/jsons/weight.json';
import Lottie from 'react-lottie-player';
import { useAppSelector } from '../app/hooks';
import weightImage from "../assets/images/Other images/Other images/vecteezy_fat-man-holding-a-measurement-tape-for-check-out-his-body_9352072.png";
import { Col, Row } from 'react-bootstrap';

export default function Weight() {
    const [weight, setWeight] = useState<string>("0");
    const path = useAppSelector((state) => state.config.path);
    return (
        <section id="sec-weight" className={'sec-weight' + (path == "weight" ? ' animate__animated animate__backInDown animate__delay-1s' : ' animate__animated animate__backOutUp animate__delay-1s hidden-section ')}>
            <h1>Your Weight</h1>

            <Row>
                <Col>
                    <div className='weight-machine'>
                        <div className='weight-input'>
                            <div className='holder'>
                                <input type='number' min={0} max={300} value={weight} onChange={(e) => {
                                    setWeight(e.target.value);
                                }} />
                                <span>KG</span>
                            </div>
                        </div>
                        <Lottie className='weight-icon' animationData={WeightJson} play />
                    </div>
                </Col>

                <Col>
                    <img className='img-fluid' src={weightImage} />
                </Col>

            </Row>

            <div className='btn-holder'>
                <a className='btn btn-weight' href='#sec-height'>
                    Next
                </a>
            </div>

        </section>
    )
}
