import React, { useContext, useEffect, useRef, useState } from 'react';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import { CustomEase } from "gsap/CustomEase";
import { RoughEase } from "gsap/EasePack";

import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import imgPerson from "../assets/images/noun-person.png";
export default function Ruler() {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const width = window.innerWidth;
    const height = window.innerHeight;
    var percentage = 0;
    let person = new Image();
    const [personHeight, setPersonHeight] = useState(60);
    useEffect(() => {

        var timer = setInterval(() => {

            if (percentage != 100) percentage++;

        }, 500);


        if (canvasRef != null && canvasRef.current != null) {
            const context = canvasRef.current.getContext("2d");
            if (context) {

                //Set Width
                context.canvas.width = width / 2;
                context.canvas.height = height;

                context.beginPath();
                context.strokeStyle = "black";
                context.lineWidth = 20;
                context.moveTo(50, 50);
                context.lineTo(50, height - 200);
                context.stroke();

                for (let index = 0; index < 11; index++) {

                    var lng = 0;
                    if (index % 2 != 0) {
                        lng = 50;
                    }
                    else {
                        lng = 0;
                    }
                    context.beginPath();
                    context.strokeStyle = "black";
                    context.lineWidth = 10;
                    context.moveTo(50, (50 + (((height - 200) / 11) * index)));
                    context.lineTo(150 - lng, (50 + (((height - 200) / 11) * index)));
                    context.stroke();


                    person.src = imgPerson;

                    context.moveTo(250, 50);
                    context.drawImage(person, 250, 150, person.width, person.height);

                    // console.log("Width :" + person.width);
                    // console.log("Height :" + person.height);
                    context.stroke();

                    // context.moveTo(500, 50);
                    context.lineWidth = 1;
                    context.font = "50px LoveYaLikeASisterSolid";

                    context.fillText(personHeight.toString() + " CM", 600, (height / 2) - 50, 200)
                    context.stroke();
                }


            }
        }
    }, [personHeight, person]);

    return (<>
        <div className='row'>
            <div className='col'>
    
                <canvas className='canvasRuler' ref={canvasRef} height={height} width={width}>

                </canvas>
            </div>
            <div className='col'>
                <div className='ruler-btn-holder'>
                    <button onClick={() => {

                        setPersonHeight(personHeight + 1);
                    }} className='btn btn-primary btn-height m-3'>+</button>
                    <button onClick={() => {
if(personHeight < 60){
    return;
}
                        setPersonHeight(personHeight - 1);
                    }} className='btn btn-primary btn-height m-3'>-</button>
                </div>
            </div>
        </div>
    </>
    )
}

