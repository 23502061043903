import React, { useEffect, useState } from 'react';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import { CustomEase } from "gsap/CustomEase";
import { RoughEase } from "gsap/EasePack";

import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";
// import logo from './logo.svg';
import './App.scss';
import 'animate.css';

import Intro from './sections/Intro';
import Gender from './sections/Gender';
import Weight from './sections/Weight';
import Height from './sections/Height';
import { useDispatch } from 'react-redux';
import { updatePath } from './features/config/configSlice';
import { useAppSelector } from './app/hooks';
function App() {

  const [loading, setLoading] = useState<boolean>(true);
  const [scrollPos, setScrollPos] = useState(0);
  var tmrScroll = null;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const dispatch = useDispatch();
  const path = useAppSelector((state) => state.config.path);

  useEffect(() => {

   setScrollPos(  window.scrollY);

   console.log(window.scrollY);

   tmrScroll = setInterval(() => {

    setScrollPos(window.scrollY);

   },500);

  },[window.scrollY]);


  useEffect(() => {
console.log("Y Pos: " + scrollPos.toString());
console.log("Path: " + path);

if(scrollPos <= (height -100)){
  console.log("I am in intro");
  dispatch(updatePath("intro"));
}
else if(scrollPos <= ((height*2)-100)){
  dispatch(updatePath("gender"));
}
else if(scrollPos <= ((height*3)-100)){
  dispatch(updatePath("weight"));
}
else if(scrollPos <= ((height*4)-100)){
  dispatch(updatePath("height"));
}


  }, [scrollPos]);

function onScroll(e:any){
console.log("Scrollong");
console.log(e);
}

  return (<>
{/* { loading?<>
<div className='center-wrapper'>
  
<Lottie
className='center'
      loop
      animationData={loadIcon}
      play
      style={{ width: 150, height: 150 }}
    />

</div>

</>: <></>} */}
    <div onScroll={onScroll}>
    <div className='body'>
                &nbsp;
            </div>
  <Intro className='' />
  <Gender />
  <Weight />
  <Height />
    </div>
    </>
  );
}
gsap.registerPlugin(useGSAP,Flip,ScrollTrigger,Observer,ScrollToPlugin,Draggable,MotionPathPlugin,EaselPlugin,PixiPlugin,TextPlugin,RoughEase,CustomEase);
export default App;
