import React from 'react';
import Lottie from 'react-lottie-player';
import maleJson from '../assets/jsons/male.json';
import femaleJson from '../assets/jsons/female.json';
import { useAppSelector } from '../app/hooks';

export default function Gender() {
    const path = useAppSelector((state) => state.config.path);
    return (
        <section id="sec-gender" className={'sec-gender' + (path == "gender"? ' animate__animated animate__backInDown animate__delay-1s': ' animate__animated animate__backOutUp animate__delay-1s hidden-section')}>
            <div className='container-fluid'>
                <h1>Select a Gender</h1>
                <div className='row'>
                    <div className='col'>
                        <a href='#sec-weight'>                        
                            <Lottie
                             className='male'
                            loop
                            animationData={maleJson}
                            play
                 
                        />
                       <h2>MALE</h2>
                       </a>

                    </div>
                    <div className='col'>
                    <a href='#sec-weight'> 
                        <Lottie
                         className='female'
                        loop
                        animationData={femaleJson}
                        play
                 
                    />
                      <h2>FEMALE</h2>
                      
                      </a>
                      </div>
                    
                </div>
            </div>
        </section>
    )
}
